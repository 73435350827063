
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'sortByValuePipe' })
export class SortByValuePipe implements PipeTransform {

    transform(array: any[], value: string, type: string, order:string,isKeyValue?: boolean): any[] {

        let i = 1;
        if (order == 'dsc') {
            i=-1;
        }

        return array.sort(function (a, b) {
            if (isKeyValue) {
                a = a.value;
                b = b.value;
            }

            if (type == 'number') {
                if (a[value] > b[value]) {
                    return i*1;
                } else {
                    return i*(-1);
                }
            }


        });

    }
}