import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { UsersComponent } from './users/users.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { OverviewComponent } from './overview/overview.component';
import { BehaviourComponent } from './behaviour/behaviour.component';
import { ProjectsComponent } from './projects/projects.component';
import { LoginComponent } from './login/login.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app-routing.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SortByValuePipe} from './pipes/sort-by-value.pipe';
import { NavBarComponent } from './nav-bar/nav-bar.component';

import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors/index';
import { NgxWidgetGridModule } from 'ngx-widget-grid';

import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {
  TicketsComponent,
  TicketDetailsDialog } from './tickets/tickets.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    UsersComponent,
    UserDetailsComponent,
    OverviewComponent,
    BehaviourComponent,
    ProjectsComponent,
    LoginComponent,
    SortByValuePipe,
    NavBarComponent,
    TicketsComponent,
    TicketDetailsDialog,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    NgxWidgetGridModule,
    MatDialogModule,
    MatTabsModule,
    MatBadgeModule,
    NgxDatatableModule
  ],
  providers: [
    httpInterceptorProviders
  ],
  entryComponents: [
    TicketDetailsDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
