import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { AppActionsService } from './app-actions.service';
import { environment } from './../../environments/environment';
import axios from 'axios';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  user = null;
  users = null;

  firebaseConfig = environment.firebaseConfig;
  firstUserLogginCheck = true;

  cloudFunctionHost = environment.cloundFunctionsHost;

  constructor(private appActions: AppActionsService, private http: HttpClient) {

    firebase.initializeApp(this.firebaseConfig);

    this.appActions.loggedIn.subscribe(() => {
      this.getAdminClaim();
      this.initUsersData();
    })
  }

  async callCloudFunction(name, params = {}) {
    const url = this.cloudFunctionHost + '/' + name;
    const res = await axios.get(url, {
      params: params,
      headers: { Authorization: 'Bearer ' + await this.getToken() }
    })


    return res.data;

  }


  async getAdminClaim() {

    await this.callCloudFunction("askAdminClaim");

    this.getToken().then(
      ok => {
        console.log('admin for ' + this.user.uid + ' claimed')
      }
    );


  }

  loginIfAlreadyLogged() {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {

        if (this.firstUserLogginCheck) { //will cause this to run only the first time user auth state changes...
          this.firstUserLogginCheck = false;
          if (user) {
            this.checkIfUserIsAdmin(user.uid).then(
              resolved => {

                this.appActions.userAlreadyLogged.next(true)
                this.onUserLogin(user.uid, resolved)
                resolve();

              },
              rejected => {
                this.appActions.userAlreadyLogged.next(false)
                reject(rejected);
              }
            )
          } else {
            reject();
          }

        }
      })
    })

  }

  checkIfUserIsAdmin(uid): any {
    return new Promise<any>((resolve, reject) => {
      firebase.database().ref('users').child(uid).once('value').then(
        snapshot => {
          if (snapshot.val().level == 'admin') {
            resolve(snapshot.val())
          } else {
            reject('user is not admin')
          }
        },
        err => {
          reject(err)
        })
    })

  }

  loginToFirebase(email, password) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then((res) => {
        console.log('%c Logged in to firebase! ', 'background: green; color: white', res.user.email);
        this.checkIfUserIsAdmin(res.user.uid).then(
          resolved => {
            this.onUserLogin(res.user.uid, resolved)

          },
          rejected => {
            reject(rejected)
          }
        )

      }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;

        console.log('%c Login error ', 'background: red; color: white', errorCode + ': ' + errorMessage)
        reject(error)
        // ...
      });

    })
  }

  logout() {
    return firebase.auth().signOut();
  }

  getToken() {
    return new Promise((resolve, reject) => {
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        // Send token to your backend via HTTPS
        // ...
        // console.log('firebase current user idtoken : ' + idToken);
        sessionStorage['firebase_access_token'] = idToken;

        resolve(idToken)

      }).catch(function (error) {
        // Handle error
        reject()
      });
    })

  }

  onUserLogin(uid, userDetails) {

    this.user = userDetails;
    this.user.uid = uid;
    //this.appActions.speak('Salut '+this.user.firstName+'. Ça va?' )
    this.appActions.speak('¿Qué tal ' + this.user.firstName + '?')
    this.appActions.loggedIn.next();
  }

  initUsersData() {
    let firstLoadFinished = false;
    firebase.database().ref('users').once('value', snapshot => {
      this.users = snapshot.val();
      this.appActions.usersFetched.next();
    })

    firebase.database().ref('users').on('child_added', snapshot => {
      if (firstLoadFinished) {

        this.users[snapshot.key] = snapshot.val();
        this.appActions.userAdded.next();
      }
    })

    firebase.database().ref('users').on('child_changed', snapshot => {
      let updatedUser = snapshot.val();
      for (let property in updatedUser) {
        this.users[snapshot.key][property] = updatedUser[property]
      }
      this.appActions.userChanged.next(snapshot.key);
    })

    firebase.database().ref('users').on('child_removed', snapshot => {
      delete this.users[snapshot.key];
      this.appActions.userRemoved.next(snapshot.key);
    })
  }

  getUsersStorageUsage(uid) {
    return new Promise<any>((resolve, reject) => {

      var totalFiles = 0;
      var counter_files = 0;
      var totalSize = 0;
      //added by ben
      //get list of files from realtime database
      firebase.database().ref('users/' + uid + '/ownedProjects').on('value',
        (snapshot) => {
          const list_project_names = snapshot.val();
          for (const project_name in list_project_names) {
            //get list of files for project owned
            firebase.storage().ref('projects/' + project_name + '/generatedModels').listAll().then(
              (res) => {
                totalFiles += res.items.length;
                res.items.forEach(
                  (itemRef) => {
                    itemRef.getMetadata().then(
                      (metadata) => {
                        totalSize += metadata.size;
                      },
                      rej => {
                        resolve(-1);
                      }
                    ).finally(() => {
                      counter_files++;
                      if (counter_files == totalFiles) {
                        resolve(totalSize);
                      }
                    })
                  }
                )
              },
              err => {
                console.warn(err);
                reject();
              }
            )
          }
        },
        err => {
          console.warn(err);
          reject();
        }
      )
    })
  }

  getProjectStorageUsage(project_name) {
    var totalFiles = 0;
    var counter_files = 0;
    var totalSize = 0;
    return new Promise<any>((resolve, reject) => {
      firebase.storage().ref('projects/' + project_name + '/generatedModels').listAll().then(
        (res) => {
          totalFiles += res.items.length;
          if (res.items.length > 0) {
            res.items.forEach(
              (itemRef) => {
                itemRef.getMetadata().then(
                  (metadata) => {
                    totalSize += metadata.size;
                  },
                  rej => {
                    resolve(-1);
                  }
                ).finally(() => {
                  counter_files++;
                  if (counter_files == totalFiles) {
                    resolve(totalSize);
                  }
                })
              }
            )
          }
          else {
            //if here means no file in storage
            reject('no file in storage');
          }
        },
        err => {
          console.warn(err);
          reject();
        }
      )
    })
  }

  getUserDetails(uid) {
    return new Promise<any>((resolve, reject) => {

      firebase.database().ref('users').child(uid).once('value').then(
        snap => {
          let details = snap.val();
          details.uid = snap.key;
          resolve(details)
        },
        bad => {
          reject(bad)
        }
      ).catch(err => { reject(err) })


    })

  }

  getProjectDetails(pid) {
    let projectDetails: any = {};
    return new Promise<any>((resolve, reject) => {
      firebase.database().ref('projects').child(pid).child('details').once('value').then(
        snap => {
          projectDetails = snap.val();
          if (projectDetails == null) {
            reject('error with project data on firebase')
            return;
          }
          this.getProjectStorageUsage(pid).then(
            ok => {
              projectDetails.storageSize = ok;
            },
            bad => {
              console.log(bad)
              projectDetails.error = bad
            }
          ).finally(() => {
            resolve(projectDetails)
            return;
          })
        },
        err => {
          console.warn(err)
          reject(err)
          return;
        }
      )
    })
  }

  updateUserDate(uid, updates) {
    return new Promise<any>((resolve, reject) => {

      firebase.database().ref('users').child(uid).update(updates).then(
        ok => {
          resolve()
        },
        bad => {
          reject(bad)
        }
      ).catch(err => reject(err))
    })
  }

  getActions() {
    return new Promise<any>((resolve, reject) => {
      firebase.database().ref('actions').once('value').then(
        snapshot => {
          resolve(snapshot.val())
        },
        rejected => {
          reject(rejected)
        }
      ).catch(err => reject(err))
    })
  }

  getVisits() {
    return new Promise<any>((resolve, reject) => {
      firebase.database().ref('visits').once('value').then(
        snapshot => {
          //console.log("CALL data.service.getVisits return value "+JSON.stringify(snapshot.val()));
          resolve(snapshot.val());
        },
        rejected => {
          console.log("CALL data.service.getVisits return error");
          reject(rejected);
        }
      ).catch(err => reject(err))
    })
  }

  async deleteProject(projectKey) {
    await this.callCloudFunction("deleteProject", { projectId: projectKey })
  }

  uploadFileStorage(file, path) {
    console.log("uploadFileStorage: " + path);
    var storageRef = firebase.storage().ref();
    var newFileRef = storageRef.child(path);
    return newFileRef.put(file);
  }

}
