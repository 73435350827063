import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppActionsService } from 'src/app/services/app-actions.service';
import { DataService } from 'src/app/services/data.service';

import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  userDetails = null;
  ownedProjects = [];
  totalStorage = null;
  uid = null;
  projectsLoaded = false;
  bimshow_url = environment.bimshow_url;
  @ViewChild('level') level: ElementRef;

  displayedColumns: string[] = ['error', 'name', 'id', 'storageSize', 'actions'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dataService: DataService, private appActions: AppActionsService, private router: Router, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
   
    this.userDetails = null;
    this.ownedProjects = [];
    this.totalStorage = null;
    this.uid = null;
    this.projectsLoaded = false;

    let uid = this.activatedRoute.snapshot.params.id;
    this.dataService.getUserDetails(uid).then(
      ok => {
        this.userDetails = ok;
        this.dataService.getUsersStorageUsage(this.userDetails.uid).then(
          resolved => {
            this.totalStorage = resolved;
          },
          rejected => {
            console.log(rejected)
          })

        this.generateOwnedProjects();
      }
    )
  }

  ngAfterViewInit() {}

  generateOwnedProjects() {
    
    let totalCalcs = 0;
    let totalProjects = this.userDetails.ownedProjects ? Object.keys(this.userDetails.ownedProjects).length : 0;
    let ownedProjects = [];

    let onDataFinished = () => {
      this.ownedProjects = ownedProjects;
      this.projectsLoaded = true;
      this.dataSource = new MatTableDataSource(this.ownedProjects)
      this.cdr.detectChanges();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (totalProjects == 0) {
      onDataFinished();
    }
    
    console.log("Owned projects user : "+JSON.stringify(this.userDetails.ownedProjects))
    for (let pid in this.userDetails.ownedProjects) {

      this.dataService.getProjectDetails(pid).then(
        resolved => {

          console.log("Owned projects resolved: "+pid)
          resolved.id = pid;
          ownedProjects.push(
            resolved
          )
        },
        rejected => {
          console.log(rejected)
          ownedProjects.push(
            {
              id: pid,
              error: rejected
            }
          )
        }

      ).finally(() => {
        totalCalcs++;
        if (totalCalcs == totalProjects) {
          console.log("Owned projects totalProjects: "+JSON.stringify(this.userDetails.ownedProjects))
          onDataFinished();
        }
      })
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  projectClicked(project) {
    console.log(project)
  }


  levelChanged(e) {
    let lastLevel = this.userDetails.level || 'none';

    let level = e.target.value;
    if (level == 'none') {
      level = null;
    }

    this.dataService.updateUserDate(this.userDetails.uid, { level: level }).then(
      ok => {

      },
      failed => {

        this.level.nativeElement.value = lastLevel;
      }
    )
  }

  openUrlInNewTab(url) {
    window.open(url, '_blank');
  }

  onProjectDeleteClicked(key) {
    this.dataService.deleteProject(key).finally(()=>{
      this.initData();
    })
  }

}
