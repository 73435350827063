import { Component, OnInit } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private dataService: DataService,private appActions:AppActionsService) { }
  user = null;
  ngOnInit(): void {

    this.appActions.loggedIn.subscribe(()=>{
      this.user = this.dataService.user;
    })
  }

  


  logoutClicked() {
    console.log('a')
    
    this.dataService.logout().then(() => {
      
      window.location.replace('/')
    })
  }


}
