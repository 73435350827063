<div class="col">
    <div class="visits widget">
        <div class="title">
            Visits (projects loaded by users)
        </div>
        <div class="content">
            <div class="spinner-container" *ngIf="!visitsDataReady">
                <mat-spinner diameter="45" strokeWidht="5"></mat-spinner>
            </div>

            <div class="properties" *ngIf="visitsDataReady">
                <div class="property">
                    <div class="label">Today:</div>
                    <div class="value">{{visitsData.today}}</div>
                </div>
                <div class="property">
                    <div class="label">This Month:</div>
                    <div class="value">{{visitsData.thisMonth}}</div>
                </div>
                <div class="property">
                    <div class="label">This Year:</div>
                    <div class="value">{{visitsData.thisYear}}</div>
                </div>
                <div class="property">
                    <div class="label">Total:</div>
                    <div class="value">{{visitsData.total}}</div>
                </div>
            </div>
        </div>
    </div>



    <div class="most-viewed-projects widget">
        <div class="title">
            Top 5 Projects
        </div>
        <div class="content">
            <div class="spinner-container" *ngIf="!projectsDataReady">
                <mat-spinner diameter="45" strokeWidht="5"></mat-spinner>
            </div>

            <div class="properties" *ngIf="projectsDataReady">
                <div class="property"
                    *ngFor="let project of ((visitedProjects | keyvalue) | sortByValuePipe:'totalVisits':'number':'dsc':'true') | slice:0:5">

                    <div class="label">{{project.value.name}} </div>
                    <div class="value"> {{project.value.totalVisits}}</div>


                </div>
            </div>

        </div>
    </div>
</div>
<div class="col"></div>
<div class="col"></div>
