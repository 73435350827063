import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-behaviour',
  templateUrl: './behaviour.component.html',
  styleUrls: ['./behaviour.component.scss']
})
export class BehaviourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
