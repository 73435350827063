import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { TicketsService } from '../services/tickets.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  listTicketIds: any;
  openTickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  loadingTickets: boolean = true;

  inputUserId: string;
  userOpentickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  userClosedTickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  loadingUserTickets: boolean = false;
  loadedUserTickets: boolean = false;

  inputTicketId: string;
  specificTicket: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  loadingSpecificTicket: boolean = false;
  loadedSpecificTicket: boolean = false;

  constructor(
    private ticketsService: TicketsService,
    public dialog: MatDialog) {}

  ngOnInit(): void {
    this.initTickets();
  }

  initTickets() {
    this.ticketsService.getTicketsOpen().toPromise()
      .then(
        listTicketIds => {
          this.loadingTickets = true;
          this.listTicketIds = listTicketIds.content;
          var counter = 0;
          var openTickets = [];
          if (!this.listTicketIds || this.listTicketIds.length == 0){
            this.loadingTickets = false;
          }
          for (const ticketId in this.listTicketIds){
            this.ticketsService.getTicket(ticketId).toPromise()
              .then(
                ticket => {
                  console.log("ticketsDetails "+ticketId);
                  console.log(ticket);
                  openTickets.push(ticket.content)
                }
              )
              .catch(
                error => {
                  console.log("error ticketsDetails");
                }
              )
              .finally(() => {
                counter += 1;
                if (counter === Object.keys(this.listTicketIds).length) {
                  console.log("ticketsDetails end");
                  this.openTickets.next(openTickets);
                  this.loadingTickets = false;
                }
              });
          }
        }
      )
      .catch(
        error => {
          console.log("error ticketsDetails");
        }
      );
  }

  getUserTickets() {
    this.ticketsService.getTicketsUsers(this.inputUserId).toPromise()
      .then(
        listTicketIds => {
          this.loadingUserTickets = true;
          var counter = 0;
          var openTickets = [];
          var closedTickets = [];
          if (!this.listTicketIds || this.listTicketIds.length == 0){
            this.loadingUserTickets = false;
          }
          for (const ticketId in this.listTicketIds){
            this.ticketsService.getTicket(ticketId).toPromise()
              .then(
                ticket => {
                  if (ticket.content.status.startsWith('open')){
                    openTickets.push(ticket.content)
                  }
                  if (ticket.content.status.startsWith('closed')){
                    closedTickets.push(ticket.content)
                  }
                }
              )
              .catch(
                error => {
                  console.log("error ticketsDetails");
                }
              )
              .finally(() => {
                counter += 1;
                if (counter === Object.keys(this.listTicketIds).length) {
                  this.userOpentickets.next(openTickets);
                  this.userClosedTickets.next(closedTickets);
                  this.loadingUserTickets = false;
                  this.loadedUserTickets = true;
                }
              });
          }
        }
      )
      .catch(
        error => {
          console.log("error ticketsDetails");
        }
      );
  }

  setTicketStatus(id,status) {
    this.ticketsService.setTicketStatus(id, status)
      .subscribe(
        result => {
          this.initTickets();
        },
        error => {
          console.log("error set ticket status: "+error.message)
        }
      );
  }


  getTicket() {
    this.ticketsService.getTicket(this.inputTicketId).toPromise()
      .then(
        ticket => {
          let ticketArray = [ticket.content]
          this.specificTicket.next(ticketArray);
          this.loadingSpecificTicket = false;
          this.loadedSpecificTicket = true;
        }
      )
      .catch(
        error => {
          console.log("error gettting ticket");
        }
      );
  }


 openDetailsDialog(ticketId) {
   console.log("openDetailsDialog : ticketId "+ticketId);
   const data = {
     data: {
       ticketId : ticketId
     },
     height: '800px',
     width: '800px'
   }
   const dialogRef = this.dialog.open(TicketDetailsDialog,data);

   dialogRef.afterClosed().subscribe(
     dialog_result => {
        this.initTickets();
        if(this.loadedUserTickets) this.getUserTickets();
        if(this.loadedSpecificTicket) this.getTicket();

      }
    );
  }
}

/*
 * for modal popup
 */
@Component({
  selector: 'tickets-detail',
  templateUrl: 'tickets-detail.html',
  styleUrls: ['tickets-detail.scss']
})
export class TicketDetailsDialog {

  ticket: any = {};
  events: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  setAddEventRunning: boolean = false;
  newEventDatas: any = {};
  newStatus: string;

  constructor(
    private _ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data,
    private ticketsService: TicketsService) {
    this.newEventDatas = {
      content: null,
      createdBy: "support"
    }
    this.getTicket(data.ticketId);
    this.getTicketEvents(data.ticketId);


    this.events.subscribe(
      state => {
        setTimeout(() => {
          this.table.rowDetail.expandAllRows();
        }, 2000);
      }
    );

  }

  addEvent() {
    this.setAddEventRunning = true;
    this.ticketsService.addEventToTicket(this.ticket.id, this.newEventDatas)
      .subscribe(
        event_result => {
          this.setAddEventRunning = false;
          this.getTicketEvents(this.ticket.id);
        },
        error => {
          console.log("error open ticket: "+error.message)
          this.setAddEventRunning = false
        }
      );
  }
  getTicket(ticketId) {
    this.ticketsService.getTicket(ticketId).toPromise()
      .then(
        ticket => {
          this.ticket = ticket.content;
          this.newStatus = ticket.content.status;
        }
      )
      .catch(
        error => {
          console.log("error gettting ticket");
        }
      );
  }
  setTicketStatus() {
    this.ticketsService.setTicketStatus(this.ticket.id, this.newStatus)
      .subscribe(
        result => {
          console.log('ok set ticket status')
          this.getTicketEvents(this.ticket.id);
        },
        error => {
          console.log("error set ticket status: "+error.message)
        }
      );
  }
  getTicketEvents(id) {
    this.ticketsService.getTicketEvents(id).toPromise()
      .then(
        events => {
          var eventsToPush = [];
          for (const event in events.content){
            eventsToPush.push(events.content[event]);
          }
          this.events.next(eventsToPush);
        }
      )
      .catch(
        error => {
          console.log("error getting ticket events");
        }
      );
  }

  handleFile(files: FileList){
    //this.data.upload_file = files.item(0);
  }

  //for textarea dynamic height
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  //for detail expand
  @ViewChild('myTable') table: any;
  expanded: any = {};
  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {}
}
