<ng-container *ngFor="let level of path; let i = index;">

    <ng-container *ngIf="i != 0">
        <div class="arrow-right">></div>
    </ng-container>
    
     <a [routerLink]="level.link">   {{level.text}}</a>


</ng-container>

