<ng-container >
    <app-nav-bar [path]="[{text:'Users',link:'/users'}]"></app-nav-bar>


 

    <div class="users-list" [ngClass]="{'hide': selectedUid != null || !dataReady }">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" #usersFilter>
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>

        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort>


                <ng-container matColumnDef="uid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let row"> {{row.uid}} </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.firstName +' '+ row.lastName}} </td>
                </ng-container>


                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                </ng-container>



                <ng-container matColumnDef="createdOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.createdOn | date:'dd/MM/yy'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastLogin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Login </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.lastLogin | date:'dd/MM/yy'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalProjects">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Projects </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.totalProjects}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="userClicked(row.uid)"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{usersFilter.value}}"</td>
                </tr>
            </table>


        </div>
        <mat-paginator pageSize="20" [pageSizeOptions]="[ 10, 20, 50]"></mat-paginator>
    </div>
</ng-container>

<div class="spinner-container" *ngIf="!this.dataReady">
    <mat-spinner diameter="70" strokeWidht="5"></mat-spinner>
</div>