import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  visits = null;
  visitedProjects = null;
  visitsData = {
    total: 0,
    thisMonth: 0,
    thisYear: 0,
    today: 0
  }

  visitsDataReady = false;
  projectsDataReady = false;

  admins = [
    'ashkenazior@gmail.com',
    'mathiasgiraud@gmail.com'
  ];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.initVisits();
  }

  initVisits() {
    this.dataService.getVisits().then(
      visits => {
        this.visits = visits;

        this.generateVisitsInfo();
      },
      rejected => {
        console.log('didnt get visit')
      }
    )
  }

  generateVisitsInfo() {
    let now = new Date();
    let month = now.getMonth();
    let year = now.getFullYear();
    let today = now.getDate();

    //generate visits per projects:
    this.visitedProjects = {};

    for (let vid in this.visits) {
      let visit = this.visits[vid];
      let pid = visit.project.id;

      if (this.admins.indexOf(visit.user.email) == -1) {
        if (this.visitedProjects[pid] == null) {
          this.visitedProjects[pid] = {
            projectId: pid,
            name: visit.project.name,
            visits: [],
            totalVisits: 0
          };
        }

        this.visitedProjects[pid].visits.push(
          {
            date: visit.date,
            user: visit.user
          }
        )

        this.visitedProjects[pid].totalVisits++;

        this.visitsData.total++;
        //now add some visit analyzing per day/month/year
        let date = new Date(visit.date)
        if (year == date.getFullYear()) {
          this.visitsData.thisYear++;
          if (month == date.getMonth()) {
            this.visitsData.thisMonth++;
            if (today == date.getDate()) {
              this.visitsData.today++;
            }
          }
        }
      }
    }
    this.projectsDataReady = true;
    this.visitsDataReady = true;
  }

}
