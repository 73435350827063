<div class="menu-button" [ngClass]="{'selected':selectedMode  == null}" routerLink="/">
    <mat-icon>insert_chart</mat-icon>
    <div class="label">Overview</div>
</div>
<div class="menu-button" [ngClass]="{'selected':selectedMode  == 'users'}" routerLink="/users">
    <mat-icon>people</mat-icon>
    <div class="label">Users</div>
</div>
<div class="menu-button" [ngClass]="{'selected':selectedMode  == 'projects'}" routerLink="/projects">
    <mat-icon>folder</mat-icon>
    <div class="label">Projects</div>
</div>
<div class="menu-button" [ngClass]="{'selected':selectedMode  == 'behaviour'}" routerLink="/behaviour">
    <mat-icon>psychology</mat-icon>
    <div class="label">Behaviour</div>
</div>


<div class="menu-button" [ngClass]="{'selected':selectedMode  == 'tickets'}" routerLink="/tickets">
    <mat-icon>bug_report</mat-icon>
    <div class="label">Tickets</div>
</div>
