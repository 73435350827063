import { Component } from '@angular/core';
import { DataService} from './services/data.service'
import { AppActionsService} from './services/app-actions.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  title = 'bimshow-admin';
  loggedIn = false;
  selectedMode = null;

  constructor(private dataService: DataService, private appActions:AppActionsService) {


    this.appActions.loggedIn.subscribe( ()=> {
      this.loggedIn = true;
    })
  }
}

