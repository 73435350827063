<img class="logo" src="../../assets/images/logobimshow10.png">
<div class="title">
    BIMSHOW - Admin
</div>
<div class="spacer"></div>


<button mat-icon-button [matMenuTriggerFor]="userMenu" [matTooltip]="'Logged in as '+user.email">
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu"  >

    <button mat-menu-item (click)="logoutClicked()">
        <span>Logout</span>
    </button>

</mat-menu>