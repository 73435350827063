import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BehaviourComponent } from './behaviour/behaviour.component';
import { TicketsComponent } from './tickets/tickets.component';
import { OverviewComponent } from './overview/overview.component';
import { ProjectsComponent } from './projects/projects.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {path: '', component: OverviewComponent },
  {path: 'users', component: UsersComponent },
  { path: 'users/:id', component: UserDetailsComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'behaviour', component: BehaviourComponent },
  { path: 'tickets', component: TicketsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
