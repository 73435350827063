<ng-container *ngIf="loggedIn">
  <app-header class="mat-elevation-z8"></app-header>
  <div class="main-container">
    <app-menu class="unselectable mat-elevation-z8"></app-menu>
    <div class="mode-container">
      <!-- <app-overview *ngIf="selectedMode==null"></app-overview>
      <app-users *ngIf="selectedMode=='users'"></app-users>
      <app-projects *ngIf="selectedMode=='projects'"></app-projects>
      <app-behaviour *ngIf="selectedMode=='behaviour'"></app-behaviour> -->

      <router-outlet></router-outlet>
      
    </div>
  </div>
</ng-container>

<app-login *ngIf="!loggedIn"></app-login>

