import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, NavigationError, NavigationStart, PRIMARY_OUTLET, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';




@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  subscription: Subscription[] = [];

  displayedColumns: string[] = [ 'firstName', 'email', 'uid', 'createdOn', 'lastLogin', 'totalProjects'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selectedUid = null;

  users = null;

  dataReady = false;

  constructor(private dataService: DataService, private appActions: AppActionsService, private router: Router, private cdr: ChangeDetectorRef) {
   


  }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.subscription.push(
      this.appActions.usersFetched.subscribe(() => {
        this.users = this.dataService.users;
        this.generateTableDatasource()
      })
    )

    this.subscription.push(
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationStart) {

        }

        if (event instanceof NavigationEnd) {
          this.selectedUid = this.getUidFromRouterURL();
        }

        if (event instanceof NavigationError) {

          console.log(event.error);
        }
      })
    )

    this.selectedUid = this.getUidFromRouterURL();
  }


  ngOnDestroy() {
    for (let sub of this.subscription) {
      sub.unsubscribe();
    }
  }

  generateTableDatasource() {
    let usersArray = [];
    for (let uid in this.users) {
      let user = this.users[uid];

      user.uid = uid;
      user.totalProjects = user.ownedProjects ? Object.keys(user.ownedProjects).length : 0;
      usersArray.push(this.users[uid])
    }


    this.dataSource = new MatTableDataSource(usersArray)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataReady = true;
    this.cdr.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  userClicked(uid) {

    this.router.navigate(['users', uid])

  }

  getUidFromRouterURL(): string {
    let urlTree = this.router.parseUrl(this.router.url);
  
    let uid = urlTree.root.children[PRIMARY_OUTLET] ? (urlTree.root.children[PRIMARY_OUTLET].segments[1] ? urlTree.root.children[PRIMARY_OUTLET].segments[1].path : null) : null;

    return uid;

  }
}
