<h2 mat-dialog-title >Ticket details {{ticket.id}}</h2>
<table>
  <tr>
    <td class="details-info-title">Type</td>
    <td>{{ticket.type}}</td>
  </tr>
  <tr>
    <td class="details-info-title">User ID</td>
    <td>{{ticket.createdBy}}</td>
  </tr>
  <tr>
    <td class="details-info-title">Title</td>
    <td>{{ticket.title}}</td>
  </tr>
  <tr>
    <td class="details-info-title">Project</td>
    <td>{{ticket.project}}</td>
  </tr>
</table>
<br/>
<div>
  <div>
    <div class="page-title">Add event</div>
    <div
      class="card">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Content</mat-label>
        <textarea rows="4" matInput [(ngModel)]="newEventDatas.content" required></textarea>
      </mat-form-field>
      <br>
      <div
        style="vertical-align: middle;margin-left: 20px;text-align: center;width: 100%;">
        <button
          mat-raised-button
          color="primary"
          (click)="addEvent()"
          [disabled]="setAddEventRunning"
          >Submit</button>
      </div>
    </div>
  </div>
  <div>
    <div class="page-title">Set status</div>
    <div
      class="card">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Type</mat-label>
        <select matNativeControl [(ngModel)]="newStatus">
          <option value="open - waiting for support">open - waiting for support</option>
          <option value="open - waiting for user">open - waiting for user</option>
          <option value="closed - not resolved">closed - not resolved</option>
          <option value="closed - resolved">closed - resolved</option>
        </select>
      </mat-form-field>
      <br>
      <div
        style="vertical-align: middle;margin-left: 20px;text-align: center;width: 100%;">
        <button
          mat-raised-button
          color="primary"
          (click)="setTicketStatus()"
          >Submit</button>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<mat-dialog-content class="mat-typography">
  <div class="page-title">History</div>
  <ngx-datatable
    *ngIf="events.value.length > 0"
    #myTable
    class="expandable"
    [rows]="events | async"
    [columnMode]="'flex'"
    [headerHeight]="0"
    [footerHeight]="0"
    rowHeight="auto"
    [limit]="10"
    [sorts]="[{ prop: 'date', dir: 'desc' }]">

    <!-- Row Detail Template -->
    <ngx-datatable-row-detail rowHeight="auto" #myDetailRow (toggle)="onDetailToggle($event)">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div style="padding-left:35px;">
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            class="full-width event"
            disabled="true">{{ row.content }}</textarea>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
    <!-- Column Templates -->
    <ngx-datatable-column
      [width]="50"
      [resizeable]="false"
      [sortable]="false"
      [draggable]="false"
      [canAutoResize]="false"
    >
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
        <a
          href="javascript:void(0)"
          [class.datatable-icon-right]="!expanded"
          [class.datatable-icon-down]="expanded"
          title="Expand/Collapse Row"
          (click)="toggleExpandRow(row)"
        >
          <mat-icon *ngIf="expanded">visibility</mat-icon>
          <mat-icon *ngIf="!expanded">visibility_off</mat-icon>
        </a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      prop="date"
      [width]="100"
      [flexGrow]="1">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      prop="createdBy"
      [name]="AddedBy"
      [width]="100"
      [flexGrow]="1">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value == 'support'">Support</span>
        <span *ngIf="value != 'support'">User</span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <br>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
