import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Input('path') path:any[];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log(this.path)
  }

  ngOnChanges(c) {
    console.log(c)
    this.cdr.detectChanges();
  }

}
