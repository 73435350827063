import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  lastErroredEmail = null;

  email = null;
  password = null;

  loginError = null;
  tryingToLogin = true;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
   
    this.dataService.loginIfAlreadyLogged().then(
      ok => {
      
        this.tryingToLogin = false;
      },
      rejected => {
        console.log(rejected)
        this.tryingToLogin = false;
      }
    )
  }



  loginClicked() {
    this.tryingToLogin = true;
    this.dataService.loginToFirebase(this.email,this.password).then(
      ok => {

      },
      err => {
        this.loginError = err;
        this.lastErroredEmail = this.email;

        this.tryingToLogin=false;
      }
    )
  }

}
