<app-nav-bar *ngIf="userDetails" [path]="[{text:'Users',link:'/users'},{text:userDetails.firstName + ' '+ userDetails.lastName, link:null}]"></app-nav-bar>
<div class="widget user-details">
    <div class="title">User's Details</div>
    <div class="spinner-container" *ngIf="!this.userDetails">
        <mat-spinner diameter="45" strokeWidht="5"></mat-spinner>
    </div>
    <div class="content" *ngIf="this.userDetails">
        <ng-container>
            <div class="avatar" [ngStyle]="{'background-image':'url('+userDetails.pictureDownloadUrl +')'}">
            </div>
            <div class="properties">
                <div class="property">
                    <div class="label">ID:</div>
                    <div class="value">{{userDetails.uid}}</div>
                </div>
                <div class="property">
                    <div class="label">Name:</div>
                    <div class="value">{{userDetails.firstName + ' ' + userDetails.lastName}}</div>
                </div>
                <div class="property">
                    <div class="label">email:</div>
                    <div class="value">{{userDetails.email}}</div>
                </div>
                <div class="property">
                    <div class="label">Total Projects:</div>
                    <div class="value">{{userDetails.totalProjects}}</div>
                </div>
                <div class="property">
                    <div class="label">Last login:</div>
                    <div class="value">{{userDetails.lastLogin | date:'dd/MM/yy hh:mm'}}</div>
                </div>
                <div class="property">
                    <div class="label">Date Created:</div>
                    <div class="value">{{userDetails.createdOn | date:'dd/MM/yy'}}</div>
                </div>
                <div class="property">
                    <div class="label">Level:</div>
                    <div class="value">
                        <select class="mat-elevation-z2" #level [value]="userDetails.level ? userDetails.level : 'none'"
                            (change)="levelChanged($event)">
                            <option value="none">Viewer</option>
                            <option value="betaTester">Beta Tester</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                </div>
                <div class="property">
                    <div class="label">Storage:</div>
                    <div class="value">
                        <ng-container *ngIf="totalStorage != null">
                            {{(totalStorage / 1024 /1024 /1024) | number:'1.2-2' }} GB
                        </ng-container>
                        <ng-container *ngIf="totalStorage == null">
                            Calculating...
                        </ng-container>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="widget ownedProjects">
    <div class="title">Owned Projects</div>
    <div class="spinner-container" *ngIf="!this.projectsLoaded">
        <mat-spinner diameter="45" strokeWidht="5"></mat-spinner>
    </div>
    <div class="content" *ngIf="this.projectsLoaded"    >
        <div class="projects-list" [ngClass]="{'hide':!projectsLoaded}" >
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" #projectsFilter>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="error">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="{'error':row.error}">
                            <ng-container *ngIf="row.error">
                                <mat-icon [matTooltip]="row.error">error</mat-icon>
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="storageSize">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Storage Size </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.storageSize ? ((row.storageSize/1024/1024 | number:'1.2-2') +'MB') : ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-icon class="more-button" [matMenuTriggerFor]="projectActions"
                                [matMenuTriggerData]="row">more_horiz</mat-icon>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="projectClicked(row)"></tr>
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4" *ngIf="ownedProjects.length != 0">No data matching the filter
                            "{{projectsFilter.value}}"</td>
                        <td class="mat-cell" *ngIf="ownedProjects.length == 0" colspan="4">No existing projects</td>
                    </tr>
                </table>
            </div>
            <mat-paginator pageSize="20" [pageSizeOptions]="[ 10, 20, 50]"></mat-paginator>
        </div>
    </div>
</div>
<mat-menu #projectActions="matMenu">
    <ng-template matMenuContent let-bimserverPoid="bimserverPoid" let-id="id">
        <button mat-menu-item  (click)="openUrlInNewTab(bimshow_url+'?p='+id)">
            <mat-icon>preview</mat-icon>
            <span>Open in BIMSHOW</span>
        </button>
        <button mat-menu-item  (click)="onProjectDeleteClicked(id)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
    </ng-template>
</mat-menu>
