import { Component, OnInit } from '@angular/core';
import { Event, NavigationStart, Router, NavigationEnd, NavigationError, PRIMARY_OUTLET } from '@angular/router';
import { AppActionsService } from '../services/app-actions.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  selectedMode = null;

  constructor(private appActions: AppActionsService, private router: Router) { 

   
    this.selectedMode = this.getModeFromRouterURL();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
     
      }

      if (event instanceof NavigationEnd) {
        this.selectedMode = this.getModeFromRouterURL();
      }

      if (event instanceof NavigationError) {
         
          console.log(event.error);
      }
  });


  }

  ngOnInit(): void {
   
  }


  getModeFromRouterURL() : string {
    let urlTree =  this.router.parseUrl(this.router.url);
  
    let mode = urlTree.root.children[PRIMARY_OUTLET] ? urlTree.root.children[PRIMARY_OUTLET] .segments[0].path : null;
    
    return mode;
  }


}
