<div class="login-container" [ngClass]="{'hide':tryingToLogin}">
    <mat-form-field class="example-full-width" >
        <mat-label>Email</mat-label>
        <input matInput type="email" [(ngModel)]="email">
    </mat-form-field>
    
    <mat-form-field class="example-full-width">
        <mat-label>Password </mat-label>
        <input matInput type="password" [(ngModel)]="password">
    </mat-form-field>

   
    
    <button mat-raised-button color="primary" (click)="loginClicked()">
        Go
    </button>

    <mat-error style="position: absolute; bottom:-0;   transform: translateY(120%);" *ngIf="lastErroredEmail == email">{{loginError}}</mat-error>
</div>

  

<mat-spinner *ngIf="tryingToLogin"></mat-spinner>