<div class="page-title">
  <span [matBadge]="openTickets.value.length" matBadgeOverlap="false">All Open tickets</span>
</div>
<div class='card'>
  <div *ngIf="loadingTickets">
    <span>Loading</span>
  </div>
  <ngx-datatable
    [rows]="openTickets | async"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [limit]="10"
    rowHeight="auto"
    [sorts]="[{ prop: 'status', dir: 'desc' },{ prop: 'date', dir: 'desc' }]">
    <ngx-datatable-column prop="createdDate" name="Creation date" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="type" name="Type" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="title" name="Title" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="lastEvent" name="Last Event" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="status" name="Status" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="id" name="Actions"[width]="100" [sortable]="false">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <button
          mat-raised-button
          (click)="openDetailsDialog(value)">Details</button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<br>
<br>
<!--With User ID-->
<div class="page-title">
  <span>User tickets</span>
</div>
<div class="card">
  <span>User ID:</span>
  <input type="text" [(ngModel)]="inputUserId">
  <button
    mat-raised-button
    (click)="getUserTickets()">Submit</button>
  <div *ngIf="loadingUserTickets">
    <span>Loading</span>
  </div>
  <br>
  <br>
  <div>
    <div class="page-title">
      <span [matBadge]="userOpentickets.value.length" matBadgeOverlap="false">Open tickets</span>
    </div>
    <ngx-datatable
      class="material"
      [rows]="userOpentickets | async"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [limit]="10"
      [sorts]="[{ prop: 'status', dir: 'desc' },{ prop: 'date', dir: 'desc' }]">
      <ngx-datatable-column prop="createdDate" name="Creation date" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | date:'yyyy/MM/dd HH:mm' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="type" name="Type" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="title" name="Title" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="lastEvent" name="Last Event" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | date:'yyyy/MM/dd HH:mm' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="status" name="Status" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="id" name="Actions"[width]="100" [sortable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <button
            mat-raised-button
            (click)="openDetailsDialog(value)">Details</button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <br>
    <div class="page-title">
      <span [matBadge]="userClosedTickets.value.length" matBadgeOverlap="false">Closed tickets</span>
    </div>
    <ngx-datatable
      class="material"
      [rows]="userClosedTickets | async"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [limit]="10"
      [sorts]="[{ prop: 'status', dir: 'desc' },{ prop: 'date', dir: 'desc' }]">
      <ngx-datatable-column prop="createdDate" name="Creation date" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | date:'yyyy/MM/dd HH:mm' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="type" name="Type" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="title" name="Title" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="lastEvent" name="Last Event" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | date:'yyyy/MM/dd HH:mm' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="status" name="Status" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="id" name="Actions"[width]="100" [sortable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <button
            mat-raised-button
            (click)="openDetailsDialog(value)">Details</button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
<!--With Ticket ID-->
<br>
<br>
<div class="page-title">
  <span>Ticket ID</span>
</div>
<div class="card">
  <span>Ticket ID:</span>
  <input type="text" [(ngModel)]="inputTicketId">
  <button
    mat-raised-button
    (click)="getTicket()">Submit</button>
  <div *ngIf="loadingSpecificTicket">
    <span>Loading</span>
  </div>
  <br>
  <br>
  <div>
    <ngx-datatable
      class="material"
      [rows]="specificTicket | async"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [limit]="10"
      [sorts]="[{ prop: 'status', dir: 'desc' },{ prop: 'date', dir: 'desc' }]">
      <ngx-datatable-column prop="createdDate" name="Creation date" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | date:'yyyy/MM/dd HH:mm' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="type" name="Type" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="title" name="Title" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="lastEvent" name="Last Event" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | date:'yyyy/MM/dd HH:mm' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="status" name="Status" [width]="100">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="id" name="Actions"[width]="100" [sortable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <button
            mat-raised-button
            (click)="openDetailsDialog(value,getTicket)">Details</button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
